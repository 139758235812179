<template>
  <div>
    <v-col>
      <v-autocomplete
        v-model="course"
        :items="courses"
        :item-text="(item) => item.Name + ' - ' + item.CategoryName"
        :item-value="(item) => item"
        class="my-2"
        label="Ders - Kategori"
        outlined
        dense
        hide-details
        @change="getAppointments"
      >
      </v-autocomplete>
      <v-autocomplete
        id="appointment-list"
        v-model="appointmentId"
        :items="appointments"
        :item-text="(item) => slotName(item)"
        :item-value="(item) => item.Id"
        label="Öğretmen"
        :disabled="course.Id <= 0 || loading"
        outlined
        dense
        hide-details
        @change="getAppointmentTemplates"
      >
        <template v-slot:item="data">
          <v-chip label small :color="fillRateStyle(data.item)">{{ slotName(data.item) }}</v-chip>
          <v-chip class="ml-2" small dark label color="amber darken-2" v-if="data.item.SubjectName">{{
            data.item.SubjectName
          }}</v-chip>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col>
      <div v-if="appointmentId > 0" class="">
        <table v-if="!loading" class="table table-responsive table-bordered table-vertical-center">
          <thead>
            <tr class="text-center">
              <th scope="col">
                <v-btn small v-if="!isNextWeek" @click="isNextWeek = !isNextWeek" color="warning"
                  >Sonraki Hafta</v-btn
                ><v-btn v-else small @click="isNextWeek = !isNextWeek" color="success"
                  >Bu Hafta</v-btn
                >
              </th>
              <th v-for="(element, idx) in thisWeek" :key="idx" scope="col">
                {{ element.key | moment }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="calendarTimes.length > 0">
              <tr class="text-center" v-for="(element, idx) in calendarTimes" :key="idx">
                <td style="width: 100px">
                  <strong>{{ element.key }}</strong>
                </td>
                <template v-for="(item, i) in element.values">
                  <td @click="editItem(item)" :key="i" :style="styleObject(item.Name)">
                    <span class="font-weight-black">{{ item.Name }}</span>
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="text-center" v-else>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
      <div v-else>
        <v-toolbar-title class="my-3 text-center">Randevu Kuralları</v-toolbar-title>
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, idx) in rules" :key="idx">
            <v-expansion-panel-header>
              {{ item.SchoolName + ' - ' }} {{ item.AppointmentCategoryName + ' - ' }}
              {{ item.Name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center" justify="center">
                <v-col>
                  <v-card outlined>
                    <div style="background-color: #5867dd">
                      <v-card-title class="font-size-h4" style="color: white; font-weight: inherit">
                        İçinde Bulunduğumuz Hafta İçin</v-card-title
                      >
                    </div>
                    <v-divider class="m-0"></v-divider>
                    <v-card-text>
                      <table class="table table-bordered table-vertical-center font-size-h6">
                        <tbody>
                          <tr>
                            <td>Aynı Gün İçin Max. Randevu Sayısı</td>
                            <td>
                              {{ item.MaxAppointmentCountForSameDay }}
                            </td>
                          </tr>
                          <tr>
                            <td>Bir Hafta İçin Max. Randevu Sayısı</td>
                            <td>
                              {{ item.MaxAppointmentCountForOneWeek }}
                            </td>
                          </tr>
                          <tr>
                            <td>Aynı Dersten Alınabilecek Max. Randevu Sayısı</td>
                            <td>
                              {{ item.MaxAppointmentCountForSameLesson }}
                            </td>
                          </tr>
                          <tr>
                            <td>Aynı Öğretmenden Arka Arkaya Randevu Alınabilir mi?</td>
                            <td>
                              {{ item.NextWeekAllowConsecutiveAppointments ? 'Evet' : 'Hayır' }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <v-card outlined>
                    <div style="background-color: #ffb822">
                      <v-card-title class="font-size-h4" style="color: white; font-weight: inherit">
                        Bir Sonraki Hafta İçin</v-card-title
                      >
                    </div>
                    <v-divider class="m-0"></v-divider>
                    <v-card-text>
                      <table class="table table-bordered table-vertical-center font-size-h6">
                        <tbody>
                          <tr>
                            <td>Aynı Gün İçin Max. Randevu Sayısı</td>
                            <td>
                              {{ item.NextWeekMaxAppointmentCountForSameDay }}
                            </td>
                          </tr>
                          <tr>
                            <td>Bir Hafta İçin Max. Randevu Sayısı</td>
                            <td>
                              {{ item.NextWeekMaxAppointmentCountForOneWeek }}
                            </td>
                          </tr>
                          <tr>
                            <td>Aynı Dersten Alınabilecek Max. Randevu Sayısı</td>
                            <td>
                              {{ item.NextWeekMaxAppointmentCountForSameLesson }}
                            </td>
                          </tr>
                          <tr>
                            <td>Aynı Öğretmenden Arka Arkaya Randevu Alınabilir mi?</td>
                            <td>
                              {{ item.NextWeekAllowConsecutiveAppointments ? 'Evet' : 'Hayır' }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <v-card outlined>
                    <div style="background-color: #fd397a">
                      <v-card-title class="font-size-h4" style="color: white; font-weight: inherit">
                        Devamsızlık Kuralı</v-card-title
                      >
                    </div>
                    <v-divider class="m-0"></v-divider>
                    <v-card-text>
                      <table class="table table-bordered table-vertical-center font-size-h6">
                        <tbody>
                          <tr>
                            <td>İlk Devamsızlıkta</td>
                            <td>
                              {{ item.FirstAbsenceForbiddenDayCount }}
                            </td>
                            <td>Gün Randevu Alınamaz</td>
                          </tr>
                          <tr>
                            <td>Toplam Devamsızlık Sayısı</td>
                            <td>
                              {{ item.ThirdTotalAbsenceCount }}
                            </td>
                            <td>Olduğunda Bir Daha Randevu Alınamaz</td>
                          </tr>
                        </tbody>
                      </table>
                      Toplam Devamsızlık Sayısı
                      <strong> {{ item.FirstTotalAbsenceCount }} </strong>
                      Olduğunda
                      <strong> {{ item.FirstTotalAbsenceForbiddenDayCount }} </strong>
                      Gün Randevu Alınamaz <br />
                      Toplam Devamsızlık Sayısı
                      <strong> {{ item.SecondTotalAbsenceCount }} </strong>
                      Olduğunda
                      <strong> {{ item.SecondTotalAbsenceForbiddenDayCount }} </strong>
                      Gün Randevu Alınamaz
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-dialog persistent v-model="dialog" max-width="330px">
        <v-card>
          <v-toolbar flat>
            <v-row class="justify-content-between">
              <v-col class="col-4">Rezerve Yap </v-col>
              <v-col class="col-4"> </v-col>
              <v-col class="col-4 text-right">
                <v-icon @click="dialog = false">mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <strong>Kategori:</strong> {{ editedItem.AppointmentCategoryName }} <br />
            <strong>Ders:</strong> {{ editedItem.CourseName }} <br />
            <strong>Öğretmen:</strong> {{ editedItem.PersonnelName }}
            <br />
            <strong>Tarih ve Saat:</strong> {{ editedItem.Date | moment }} {{ editedItem.Time }}
            <br />
            <strong> Mekan: </strong> {{ editedItem.ClassroomName }}
            <br />
            <span v-if="editedItem.Description != null">
              <strong>Açıklama:</strong> {{ editedItem.Description }}
            </span>
            <br/>
            <span v-if="editedItem.SubjectName != null">
              <strong>Konu:</strong>  {{ editedItem.SubjectName }}
            </span>
          </v-card-text>
          <v-card-actions class="justify-content-center">
            <v-btn color="primary"  @click="save"> RANDEVU AL </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'

export default {
  name: 'AppointmentList',
  data() {
    return {
      course: {},
      courses: [],
      appointmentId: 0,
      appointments: [],
      appointmentTemplates: [],
      rules: [],
      dialog: false,
      editedItem: {},
      isNextWeek: false,
      loading: false,
      consecutiveAppointmentOptions: [
        { Name: 'Hayır, Alınamaz', Value: false },
        { Name: 'Evet, Alınabilir', Value: true }
      ]
    }
  },
  watch: {
    isNextWeek() {
      if (!this.loading && this.appointmentId > 0) this.getAppointmentTemplates()
    }
  },
  computed: {
    thisWeek() {
      if (this.appointmentTemplates != null) {
        return this.groupByArray(this.appointmentTemplates, 'Date')
      } else {
        return []
      }
    },

    calendarTimes() {
      if (this.appointmentTemplates != null) {
        return this.groupByArray(this.appointmentTemplates, 'Time')
      } else {
        return []
      }
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('DD.MM.YYYY dddd') : ''
    }
  },
  created() {
    this.getAppointmentCourses()
    this.getAppointmentRules()
  },
  methods: {
    getAppointmentCourses() {
      ApiService.setHeader()
      ApiService.post('api/Appointment/course-list', {})
        .then((data) => {
          this.courses = data.data
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    getAppointments() {
      this.loading = true
      this.appointmentId = 0
      this.appointments = []
      this.appointmentTemplates = []
      this.isNextWeek = false
      ApiService.setHeader()
      ApiService.post('api/Appointment/appointment-list', {
        AppointmentCategoryId: this.course.CategoryId,
        CourseId: this.course.Id
      })
        .then((data) => {
          this.appointments = data.data

          this.loading = false
        })
        .catch(({ response }) => {
          this.loading = false
          ApiService.showError(response)
        })
    },
    getAppointmentTemplates() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Appointment/student-table-query', {
        AppointmentId: this.appointmentId,
        IsNextWeek: this.isNextWeek
      })
        .then((data) => {
          this.appointmentTemplates = data.data
          if (this.appointmentTemplates && !this.isNextWeek) {
            this.isNextWeek =
              this.appointmentTemplates.filter((s) => s.Name == 'Kapalı').length ==
              this.appointmentTemplates.length
          }
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loading = false
        })
    },

    editItem(item) {
      if (
        item.AppointmentTypeName === 'Grup' &&
        item.Name === 'Grup' &&
        item.Name !== 'Kapalı' &&
        item.Name !== 'Süre Doldu'
      ) {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      } else if (item.Name === 'Boş' && item.Name !== 'Kapalı' && item.Name !== 'Süre Doldu') {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      }
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {})
      })
    },

    save() {
      let model = {
        AppointmentTemplateId: this.editedItem.Id,
        IsNextWeek: this.isNextWeek,
        IsGroup: this.editedItem.AppointmentTypeName === 'Grup'
      }
      ApiService.setHeader()
      ApiService.put('api/AppointmentStudent/save-student', model)
        .then(() => {
          this.close()
          this.getAppointmentTemplates()
          this.$emit('updateTemplateEvent')
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },

    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({ key: v, values: [x] })
        }
        return rv
      }, [])
    },

    styleObject(status) {
      if (status === 'Kapalı') {
        return { 'background-color': '#E53935' }
      } else if (status === 'Süre Doldu') {
        return { 'background-color': '#1E88E5' }
      } else if (status === 'Boş') {
        return { 'background-color': '#7CB342' }
      } else {
        return { 'background-color': '#90A4AE' }
      }
    },

    slotName(item) {
      return `${item.Name} - (${item.StartDate.substring(0,5)}-${item.EndDate.substring(0,5)}) - ${item.FilledSlotCount}/${item.TotalSlotCount}`
    },

    fillRateStyle(item) {
      let fillRate = (item.FilledSlotCount / item.TotalSlotCount) * 100
      if (fillRate > 99) {
        return 'error'
      } else if (fillRate > 80 && fillRate < 100) {
        return 'warning'
      } else return 'success'
    },

    getAppointmentRules() {
      ApiService.setHeader()
      ApiService.post('api/AppointmentRule', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
        .then((data) => {
          this.rules = data.data.Results
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
