<template>
  <v-container class="p-0">
    <v-row no-gutters>
      <v-col class="text-center" align-self="center">
        <h5 class="m-0">{{ showRequests ? 'GEÇMİŞ İSTEKLER' : 'RANDEVU İSTE' }}</h5>
      </v-col>
      <v-col class="d-flex justify-content-end">
        <v-btn v-if="!showRequests" dark color="warning" small @click="changeStatus"
          >Geçmiş İstekler</v-btn
        >
        <v-btn v-else color="success" dark small @click="changeStatus"> Randevu İste</v-btn></v-col
      >
    </v-row>
    <v-row v-if="!showRequests">
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
        <v-select
          v-model="editedItem.CourseId"
          outlined
          dense
          hide-details
          label="Ders"
          :item-text="(item) => item.Name"
          :item-value="(item) => item.Id"
          :items="courses"
          @change="courseOnChange()"
        ></v-select>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
        <v-select
          :disabled="editedItem.CourseId == 0"
          v-model="editedItem.PersonnelId"
          outlined
          dense
          hide-details
          :item-text="(item) => item.Name"
          :item-value="(item) => item.Id"
          label="Öğretmen"
          :items="personnels"
        ></v-select>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
        <c-datetime-picker
          v-model="editedItem.AppointmentDate"
          label="Randevu İstenilen Tarih"
          hide-details
          outlined
          dense
        ></c-datetime-picker>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
        <v-textarea
          v-model="editedItem.Note"
          label="Not"
          outlined
          dense
          hide-details
          auto-grow
          rows="2"
        >
        </v-textarea>
      </v-col>
      <v-col :cols="12" class="d-flex justify-content-center">
        <v-btn large color="primary" @click="save" :disabled="saving">KAYDET</v-btn>
      </v-col>
    </v-row>
    <v-row v-else no-gutters>
      <v-col>
        <v-list class="mt-5" subheader>
          <template v-for="item in rows">
            <v-list-item :key="item.Id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.Course.Name }} - {{ item.Personnel.Name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap mt-1"> {{ item.Note }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="item.AppointmentDate">
                <v-chip outlined small color="warning"> {{ item.AppointmentDate | moment }}</v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="item.Id" class="mx-2"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
  name: 'AppointmentRequests',
  data() {
    return {
      rows: [],
      courses: [],
      personnels: [],
      editedItem: {
        CourseId: 0,
        PersonnelId: 0,
        AppointmentDate: null,
        Note: ''
      },
      showRequests: false,
      saving: false
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
    }
  },
  created() {
    this.getCoursesFromApi()
  },
  methods: {
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/AppointmentRequest', {
        sortBy: ['Id'],
        descending: [true],
        rowsPerPage: -1,
        page: 1
      })
        .then((data) => {
          this.rows = data.data.Results
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    getCoursesFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Course', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          NameList: true,
          ForAppointmentCourse: true
        }
      })
        .then((data) => {
          this.courses = data.data.Results
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    getPersonnelsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Personnel', {
        sortBy: ['FirstName'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: { CourseId: this.editedItem.CourseId }
      })
        .then((data) => {
          this.personnels = data.data.Results
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    courseOnChange() {
      if (this.editedItem.CourseId > 0) this.getPersonnelsFromApi()
      else {
        this.personnels = []
        this.editedItem.PersonnelId = 0
      }
    },
    save() {
      this.saving = true
      ApiService.setHeader()
      ApiService.put('api/AppointmentRequest', this.editedItem)
        .then(() => {
          Swal.fire({
            toast: true,
            position: 'bottom',
            icon: 'success',
            title: 'Talebiniz alındı.',
            showConfirmButton: false,
            timer: 1500
          })
          this.changeStatus()
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.saving = false
        })
    },
    changeStatus() {
      if (!this.showRequests) this.getDataFromApi()
      else
        this.editedItem = {
          CourseId: 0,
          PersonnelId: 0,
          AppointmentDate: null,
          Note: ''
        }
      this.showRequests = !this.showRequests
      this.saving = false
    }
  }
}
</script>
